import { Injectable, OnDestroy } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';
import { DialogHandlerService } from '@core/services/dialog-handler.service';
import { FormlyFieldConfig, FormlyFormOptions } from '@ngx-formly/core';
import { FormlyJsonschema } from '@ngx-formly/core/json-schema';
import { Store } from '@ngxs/store';
import { ORDER_CREATE_UI } from '@shared/constants';
import { JsonSchemaService } from 'app/json-schema-forms/services/json-schema.service';
import { WorkflowScreen } from 'app/json-schema-forms/store/models/workflow-screen.model';
import { FetchInjectedScreen } from 'app/json-schema-forms/store/workflow.actions';
import { filter, first, Observable, Subject, takeUntil, tap } from 'rxjs';
import { OrderCreateModel } from '../models/order.create.model';
import { Order } from '../models/order.model';
import { CreateOrder } from '../store/wizard.actions';
import { WizardService } from './wizard.service';

@Injectable({
  providedIn: 'root',
})
export class ViewOrderService implements OnDestroy {
  componentDialogRef: MatDialogRef<any>;
  editMode = false;
  unsubscribe$ = new Subject<void>();
  constructor(
    public jsonSchemaService: JsonSchemaService,
    public dialogHandlerService: DialogHandlerService,
    public formlyJsonschema: FormlyJsonschema,
    private store$: Store,
    private wizardService: WizardService
  ) {}

  openOrderView(
    order?: Order,
    injectedScreen$?: Observable<WorkflowScreen>,
    dialogFields?: FormlyFieldConfig[],
    form?: FormGroup,
    options?: FormlyFormOptions,
    dialogRef?: MatDialogRef<any>,
    viewOnly: boolean = false
  ): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
    this.unsubscribe$ = new Subject<void>();
    this.editMode = !!order;
    this.store$.dispatch(new FetchInjectedScreen(ORDER_CREATE_UI));
    injectedScreen$
      .pipe(
        filter(screen => !!screen),
        first(),
        tap(screen => {
          if (screen) {
            let model = { order: order || {} };
            if (order) {
              const extractedKeys = this.jsonSchemaService.generateDataModel(screen.schema.properties);
              const generatedModel = this.jsonSchemaService.populateTemplate(extractedKeys.order, order);
              model = { order: generatedModel };
            }

            if (dialogRef) {
              this.componentDialogRef = dialogRef;
            }

            dialogFields = this.viewOrderFormGenerator(screen.schema, viewOnly, order);

            this.addCascadingFieldsAndValidations(dialogFields, order);
            this.dialogHandler(dialogFields, model, form, options, order, screen);
          }
        })
      )
      .subscribe();
  }

  addCascadingFieldsAndValidations(dialogFields: FormlyFieldConfig[], order: Order) {
    const buyerField = this.getFieldByName(dialogFields, 'buyerId');
    const cargoPickupField = this.getFieldByName(dialogFields, 'cargoPickupLocation');
    const sellerField = this.getFieldByName(dialogFields, 'sellerId');
    const cargoDropOffField = this.getFieldByName(dialogFields, 'cargoDropOffLocation');
    const countryOfOriginField = this.getFieldByName(dialogFields, 'countryOfOrigin');
    const countryOfDestinationField = this.getFieldByName(dialogFields, 'countryOfDestination');
    const shipmentModeField = this.getFieldByName(dialogFields, 'preferredShipmentMode');
    const originLocationField = this.getFieldByName(dialogFields, 'originLocation');
    const destinationLocationField = this.getFieldByName(dialogFields, 'destinationLocation');
    const transhipmentField = this.getFieldByName(dialogFields, 'transhipment');
    const loadDetailsField = this.getFieldByName(dialogFields, 'loadDetails');

    shipmentModeField.hooks = {
      onInit: field => {
        field.formControl.valueChanges
          .pipe(
            takeUntil(this.unsubscribe$),
            tap(selectedMode => {
              loadDetailsField.props['onClick'](selectedMode, order);
              if (selectedMode) {
                transhipmentField.formControl.setValue(order ? order?.shipmentDetails?.transhipment : false);
                this.setOriginDestinationFieldValidations(
                  selectedMode?.value ?? selectedMode,
                  originLocationField,
                  destinationLocationField,
                  countryOfOriginField,
                  countryOfDestinationField
                );
              }
            })
          )
          .subscribe();
      },
    };

    sellerField.hooks = {
      onInit: field => {
        field.formControl.valueChanges
          .pipe(
            takeUntil(this.unsubscribe$),
            tap(searchVal => {
              if (searchVal) {
                const isSeller = field.formControl?.value?.value === order?.parties?.sellerId;
                cargoPickupField.props['gqlQuery'].variables.orgId = searchVal?.value || '';
                cargoPickupField.formControl.setValue(isSeller ? order?.shipmentDetails?.cargoPickupLocation : '');
                cargoPickupField.formControl.markAsDirty();
                cargoPickupField.formControl.markAsTouched();
              }
            })
          )
          .subscribe();
      },
    };

    buyerField.hooks = {
      onInit: field => {
        field.formControl.valueChanges
          .pipe(
            takeUntil(this.unsubscribe$),
            tap(searchVal => {
              if (searchVal) {
                const isBuyer = field.formControl?.value?.value === order?.parties?.buyerId;
                cargoDropOffField.props['gqlQuery'].variables.orgId = searchVal?.value || '';
                cargoDropOffField.formControl.setValue(isBuyer ? order?.shipmentDetails?.cargoDropOffLocation : '');
                cargoDropOffField.formControl.markAsDirty();
                cargoDropOffField.formControl.markAsTouched();
              }
            })
          )
          .subscribe();
      },
    };

    cargoPickupField.hooks = {
      onInit: field => {
        field.formControl.valueChanges
          .pipe(
            takeUntil(this.unsubscribe$),
            tap(searchVal => {
              if (searchVal) {
                if (searchVal?.countryCode) {
                  this.wizardService.getCountriesByShortCodes([searchVal.countryCode]).subscribe(countries => {
                    const country = countries[0];
                    if (country) {
                      countryOfOriginField.formControl.setValue({
                        label: country.countryName,
                        value: country._id,
                        countryCode: searchVal?.countryCode,
                      });
                    }
                  });
                } else if (searchVal?.address?.country) {
                  this.wizardService.getCountriesByNames([searchVal.address.country]).subscribe(countries => {
                    const country = countries[0];
                    if (country) {
                      countryOfOriginField.formControl.setValue({
                        label: country.countryName,
                        value: country._id,
                        countryCode: country?.countryCode,
                      });
                    }
                  });
                } else {
                  countryOfOriginField.formControl.setValue({
                    label: '',
                    value: '',
                  });
                }

                const sameLocation = field.formControl?.value?.value === order?.shipmentDetails?.cargoPickupLocation;
                countryOfOriginField.formControl.setValue(sameLocation ? order?.shipmentDetails?.countryOfOrigin : '');
                countryOfOriginField.formControl.markAsDirty();
                countryOfOriginField.formControl.markAsTouched();
              } else {
                // countryOfOriginField.formControl.setValue('');
              }
            })
          )
          .subscribe();
      },
    };

    cargoDropOffField.hooks = {
      onInit: field => {
        field.formControl.valueChanges
          .pipe(
            takeUntil(this.unsubscribe$),
            tap(searchVal => {
              if (searchVal) {
                countryOfDestinationField.formControl.markAsDirty();
                countryOfDestinationField.formControl.markAsTouched();
                if (searchVal?.address?.country) {
                  this.wizardService.getCountriesByNames([searchVal.address.country]).subscribe(countries => {
                    const country = countries[0];
                    if (country) {
                      countryOfDestinationField.formControl.setValue({
                        label: country.countryName,
                        value: country._id,
                        countryCode: country?.countryCode,
                      });
                    }
                  });
                } else if (searchVal?.countryCode) {
                  this.wizardService.getCountriesByShortCodes([searchVal.countryCode]).subscribe(countries => {
                    const country = countries[0];
                    if (country) {
                      countryOfDestinationField.formControl.setValue({
                        label: country.countryName,
                        value: country._id,
                        countryCode: country?.countryCode,
                      });
                    }
                  });
                } else {
                  countryOfDestinationField.formControl.setValue({
                    label: '',
                    value: '',
                  });
                }

                const sameLocation = field.formControl?.value?.value === order?.shipmentDetails?.cargoDropOffLocation;
                countryOfDestinationField.formControl.setValue(
                  sameLocation ? order?.shipmentDetails?.countryOfDestination : ''
                );
              } else {
                // countryOfDestinationField.formControl.setValue('');
              }
            })
          )
          .subscribe();
      },
    };

    this.setCountryValidations(
      countryOfOriginField,
      countryOfDestinationField,
      originLocationField,
      destinationLocationField
    );
    this.setupOriginLocationField(originLocationField);
    this.setupDestinationLocationField(destinationLocationField);
  }

  setupOriginLocationField(originLocationField: FormlyFieldConfig) {
    originLocationField.templateOptions.click = () => {
      originLocationField.formControl.updateValueAndValidity();
    };
  }

  setupDestinationLocationField(destinationLocationField: FormlyFieldConfig) {
    destinationLocationField.templateOptions.click = () => {
      destinationLocationField.formControl.updateValueAndValidity();
    };
  }

  dialogHandler(
    dialogFields: FormlyFieldConfig[],
    model: any,
    form: FormGroup,
    options: FormlyFormOptions,
    order: Order,
    screen
  ) {
    this.componentDialogRef = this.dialogHandlerService.openSchemaDialog(dialogFields, model, form, options, {
      backdropClass: 'backdropBackground',
      width: '800px',
      height: '100%',
      panelClass: 'custom-dialog-container',
    });
    this.componentDialogRef.componentInstance.submitModel
      .pipe(
        takeUntil(this.unsubscribe$),
        tap(result => {
          const buttonObject = this.jsonSchemaService.findObjectByKey(screen.schema, 'confirm');
          const variableObject = this.jsonSchemaService.findObjectByKey(
            buttonObject['confirm'],
            'variables'
          )?.variables;
          const orderCreateModel = this.jsonSchemaService.nestedVariableExtractor(
            variableObject,
            result as object
          ) as OrderCreateModel;

          if (order) {
            orderCreateModel._id = order._id;
          }

          if (orderCreateModel) {
            this.store$.dispatch(new CreateOrder(orderCreateModel));
          }
        })
      )
      .subscribe();
  }

  private getFieldByName(fields: FormlyFieldConfig[], fieldName: string): FormlyFieldConfig {
    return this.jsonSchemaService.findFormlyFieldConfig(fields[0].fieldGroup, fieldName);
  }

  private setOriginDestinationFieldValidations(
    mode: string,
    originLocationField: FormlyFieldConfig,
    destinationLocationField: FormlyFieldConfig,
    originCountryField: FormlyFieldConfig,
    destinationCountryField: FormlyFieldConfig
  ) {
    if (!this.editMode) {
      this.resetField(originLocationField);
      this.resetField(destinationLocationField);
    }
    switch (mode) {
      case 'AIR':
        this.buildValidationMessage(
          originLocationField,
          'Origin Airport',
          'Please choose the Origin Airport',
          destinationLocationField,
          'Destination Airport',
          'Please choose the Destination Airport'
        );

        this.buildOriginDestinationQueryVariables(
          originLocationField,
          'AIRPORT',
          originCountryField,
          destinationLocationField,
          'AIRPORT',
          destinationCountryField
        );
        break;
      case 'SEA':
        this.buildValidationMessage(
          originLocationField,
          'Origin Port',
          'Please choose the Origin Port',
          destinationLocationField,
          'Destination Port',
          'Please choose the Destination Port'
        );
        this.buildOriginDestinationQueryVariables(
          originLocationField,
          'SEAPORT',
          originCountryField,
          destinationLocationField,
          'SEAPORT',
          destinationCountryField
        );
        break;
      case 'SEA_AIR':
        this.buildValidationMessage(
          originLocationField,
          'Origin Port',
          'Please choose the Origin Port',
          destinationLocationField,
          'Destination Airport',
          'Please choose the Destination Airport'
        );

        this.buildOriginDestinationQueryVariables(
          originLocationField,
          'SEAPORT',
          originCountryField,
          destinationLocationField,
          'AIRPORT',
          destinationCountryField
        );
        break;
      case 'AIR_SEA':
        this.buildValidationMessage(
          originLocationField,
          'Origin Airport',
          'Please choose the Origin Airport',
          destinationLocationField,
          'Destination Port',
          'Please choose the Destination Port'
        );

        this.buildOriginDestinationQueryVariables(
          originLocationField,
          'AIRPORT',
          originCountryField,
          destinationLocationField,
          'SEAPORT',
          destinationCountryField
        );

        break;
    }

    originLocationField.hide = false;
    destinationLocationField.hide = false;
  }

  private setCountryValidations(
    originCountry: FormlyFieldConfig,
    destinationCountry: FormlyFieldConfig,
    originLocation: FormlyFieldConfig,
    destinationLocation: FormlyFieldConfig
  ) {
    originCountry.hooks = {
      onInit: field => {
        field.formControl.valueChanges
          .pipe(
            takeUntil(this.unsubscribe$),
            tap(searchVal => {
              if (searchVal && typeof searchVal === 'object' && searchVal?.countryCode) {
                originLocation.props['gqlQuery']['variables']['countryCode'] = searchVal?.countryCode;
                //if the formcontrol value is not an object its in edit mode. in edit mode, should ignore the resetField()
                if (!this.editMode && typeof searchVal === 'object') {
                  this.resetField(originLocation);
                }
              }
            })
          )
          .subscribe();
      },
    };

    destinationCountry.hooks = {
      onInit: field => {
        field.formControl.valueChanges
          .pipe(
            takeUntil(this.unsubscribe$),
            tap(searchVal => {
              if (searchVal && typeof searchVal === 'object' && searchVal?.countryCode) {
                destinationLocation.props['gqlQuery']['variables']['countryCode'] = searchVal?.countryCode;
                if (!this.editMode && typeof searchVal === 'object') {
                  this.resetField(destinationLocation);
                }
              }
            })
          )
          .subscribe();
      },
    };
  }

  private resetField(field: FormlyFieldConfig) {
    field.formControl.setValue('');
    field.formControl.markAsDirty();
    field.formControl.markAsTouched();
    field.formControl.updateValueAndValidity();
  }

  private buildOriginDestinationQueryVariables(
    originLocationField: FormlyFieldConfig,
    originLocationType: string,
    originCountryField: FormlyFieldConfig,
    destinationLocationField: FormlyFieldConfig,
    destinationLocationType: string,
    destinationCountryField: FormlyFieldConfig
  ) {
    originLocationField.props['gqlQuery']['variables']['locationType'] = originLocationType;
    originLocationField.props['gqlQuery']['variables']['countryCode'] =
      originCountryField.formControl.value?.countryCode ?? '';

    destinationLocationField.props['gqlQuery']['variables']['locationType'] = destinationLocationType;
    destinationLocationField.props['gqlQuery']['variables']['countryCode'] =
      destinationCountryField.formControl.value?.countryCode ?? '';
  }

  private buildValidationMessage(
    originLocationField: FormlyFieldConfig,
    originLocationLabel: string,
    originLocationValidation: string,
    destinationLocationField: FormlyFieldConfig,
    destinationLocationLabel: string,
    destinationLocationValidation: string
  ) {
    originLocationField.props.label = originLocationLabel;
    originLocationField.validation.messages['required'] = originLocationValidation;

    destinationLocationField.props.label = destinationLocationLabel;
    destinationLocationField.validation.messages['required'] = destinationLocationValidation;
  }

  viewOrderFormGenerator(schema: any, viewOnly: boolean, order?: Order) {
    return [
      this.formlyJsonschema.toFieldConfig(schema, {
        map: field => {
          switch (field.key) {
            case 'footer':
              field.fieldGroup.find(f => f.key === 'cancel').props['onClick'] = () => this.componentDialogRef.close();
              break;
            case 'cancel':
              if (viewOnly) {
                field.props['onClick'] = () => this.componentDialogRef.close();
                field.props['disabled'] = false;
              }
              break;
            case 'order':
              {
                if (order?.orderRefNumber) {
                  field.props['label'] = order.orderRefNumber;
                }
                const documentUploadField = field.fieldGroup?.find(f => f.key === 'documentUpload');
                if (documentUploadField) {
                  const schemaCategories = documentUploadField.props['categories'] || [];
                  const categorizedFiles = {};
                  schemaCategories.forEach(category => {
                    categorizedFiles[category] = [];
                  });

                  // Populate with existing files from order.shippingDocuments
                  if (order?.shippingDocuments) {
                    order.shippingDocuments.forEach(doc => {
                      if (categorizedFiles[doc.category]) {
                        categorizedFiles[doc.category] = doc.documentDetails;
                      }
                    });
                  }

                  // Merge with newly uploaded documents
                  const orderDocuments = this.store$.selectSnapshot(state => state.wizard.orderDocuments);
                  Object.keys(orderDocuments).forEach(category => {
                    if (categorizedFiles[category]) {
                      categorizedFiles[category] = [...categorizedFiles[category], ...orderDocuments[category]];
                    } else {
                      categorizedFiles[category] = orderDocuments[category];
                    }
                  });

                  documentUploadField.props['categories'] = categorizedFiles;
                }
              }
              break;
            case 'createOrders':
              if (field.props['openDialog'] && field.props['gqlQuery']) {
                field.props['onClick'] = () => this.openOrderView(field.props['gqlQuery']);
              }
              break;
            case 'loadDetails':
              field.props['viewOnly'] = viewOnly;
              break;
            default:
              if (viewOnly && field.key !== 'footer') {
                field.props['disabled'] = true;
              }
          }
          return field;
        },
      }),
    ];
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }
}
